
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { StatisticData } from '../types';
import { statistics } from '../data';
const useStatistics = () => {
    const { t } = useTranslation('home');
    const stats: StatisticData[] = useMemo(() => [
        {
            description: 'years of experience',
            getFormattedValue: (value: string) => `+${value}`,
            value: statistics.companyExperienceYears,
        },
        {
            description: 'SMS in %{year}',
            descriptionParams: {
                year: '2023',
            },
            getFormattedValue: (value: string) => `+${value}${t('B')}`,
            value: statistics.smsSend,
        },
        {
            description: 'customers',
            getFormattedValue: (value: string) => `+${value}`,
            value: statistics.customersAmount,
        },
        {
            description: 'employees',
            getFormattedValue: (value: string) => `+${value}`,
            value: statistics.employeesAmount,
        },
        {
            description: 'satisfied users',
            getFormattedValue: (value: string) => `${value}%`,
            value: statistics.satisfiedUsersRate,
        },
        {
            description: 'NPS',
            getFormattedValue: (value: string) => value,
            value: statistics.npsScore,
        },
    ], [t]);
    return stats;
};
export default useStatistics;

    async function __Next_Translate__getStaticProps__19192d94e6f__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/Stats/hooks/useStats',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19192d94e6f__ as getStaticProps }
  